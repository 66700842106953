<template>
  <div class="w-100">
    <div class="user__dashboard__header">
      <b-row class="align-items-end">
        <b-col lg="6" xl="4">
          <InputFilter
            label="Tìm kiếm khách hàng"
            placeholder="Nhập tên hoặc số điện thoại"
            class="form__search"
            :options="[]"
          />
        </b-col>
      </b-row>
    </div>
    <div class="user__dashboard__main">
      <div class="user__dashboard__content container-fluid"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UserDashboardFind',
  components: {
    InputFilter: () => import('./components/InputFilter.vue'),
  },
  data() {
    return {
      navs: [
        {
          name: 'Tổng hợp',
          to: 'over_view',
        },
        {
          name: 'Chỉ số sinh học',
          to: 'biological',
        },
        {
          name: 'Lịch trình',
          to: 'schedule',
        },
        {
          name: 'Bài học',
          to: 'lessons',
        },
        {
          name: 'Kết quả khảo sát',
          to: 'survey_result',
        },
        {
          name: 'Báo cáo học viên',
          to: 'student_report',
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.coach__info {
  display: grid;
  grid-template-columns: repeat(6, max-content);
  column-gap: 28px;

  @media only screen and (max-width: 1366px) {
    column-gap: 16px;
  }
}

.user__dashboard {
  &__header {
    padding: 16px 24px;
    background: #fff;
    border-top: 1px solid #ebedf3;
  }

  &__main {
    display: grid;
    grid-template-columns: 1fr;
    padding: 24px;
    column-gap: 24px;
    min-height: calc(100% - 98px);
  }

  &__sidebar {
    display: grid;
    grid-template-rows: repeat(1, min-content);
  }

  &__menu,
  &__comment,
  &__content {
    background: #fff;
    border-radius: 6px;
    padding: 16px;
  }

  &__content {
    overflow-x: hidden;
  }
}

.nav {
  &__list {
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    row-gap: 20px;
    list-style: none;
  }

  &__item {
    a {
      color: #000;

      &.nav__active {
        color: #008479;
        font-weight: 600;
      }
    }
  }
}

.actions {
  display: grid;
  grid-template-rows: repeat(3, max-content);
  row-gap: 12px;

  background: #fff;
  border-radius: 6px;
  padding: 16px;
}

.text-gray {
  color: #888c9f;
  font-size: 12px;
  line-height: 16px;
}

.infomation {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 16px;
}

.study__progress {
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 6px 12px;
  width: 100px;
  margin-top: 8px;

  &.need__improve {
    background-color: #e53935;
  }
  &.improving {
    background-color: #f58220;
  }
  &.right__way {
    background-color: #01645a;
  }
  &.very__good {
    background-color: #21a567;
  }
  &.good {
    background-color: #50c087;
  }
  &.pending {
    background-color: #454649;
  }
}
.support {
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding: 6px 12px;
  width: 100px;
  margin-top: 8px;

  &.prefer {
    background-color: #f58220;
  }
  &.normal {
    background-color: #01645a;
  }
}
</style>

<style lang="scss">
.form__search {
  margin-bottom: 0;
  min-width: 220px;
}
</style>
